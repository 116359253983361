import {ChangeDetectionStrategy, Component} from '@angular/core'
import {LinkService} from '../../../../i18n/link.service'
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars'
import {SideMenuService} from '../side-menu.service'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {TranslocoDirective} from '@ngneat/transloco'

@Component({
  selector: 'bgo-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconComponent, TranslocoDirective],
})
export class HamburgerMenuComponent {
  readonly faBars = faBars

  constructor(
    readonly sideMenuService: SideMenuService,
    readonly linkService: LinkService,
  ) {}
}
