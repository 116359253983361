import {Routes} from '@angular/router'
import {I18nGuard} from './core/i18n/i18n.guard'
import {PUBLIC_ROUTES} from './shared/routing/public.routes'
import {HideOfferingSearchInNavbarRouteParam, MinimalistFooterRouteParam} from './core/components/layout/layout.service'
import {HomePageTypeKey, HomePageTypes} from './pages/public/home-page/home-page.types'

export const routes: Routes = [
  {
    path: PUBLIC_ROUTES.HOME.routingPath,
    loadChildren: () => import('./pages/public/home-page/home-page.routes'),
  },
  {
    // lang found in the path
    path: ':locale',
    canActivateChild: [I18nGuard],
    children: [
      {
        path: PUBLIC_ROUTES.UNSUPPORTED_LANGUAGE_PAGE.routingPath,
        loadChildren: () =>
          import('./pages/public/unsupported-language/unsupported-language.module').then(
            m => m.UnsupportedLanguageModule,
          ),
      },
      // Home page (localized)
      {
        path: PUBLIC_ROUTES.HOME_LOCALIZED.routingPath,
        loadChildren: () => import('./pages/public/home-page/home-page.routes'),
      },
      // Mobiliar home page
      {
        path: PUBLIC_ROUTES.MOBILIAR_HOME_PAGE_DE.routingPath,
        loadChildren: () => import('./pages/public/home-page/home-page.routes'),
        data: {
          [HomePageTypeKey]: HomePageTypes.Mobiliar,
        },
      },
      {
        path: PUBLIC_ROUTES.MOBILIAR_HOME_PAGE_EN.routingPath,
        loadChildren: () => import('./pages/public/home-page/home-page.routes'),
        data: {
          [HomePageTypeKey]: HomePageTypes.Mobiliar,
        },
      },
      {
        path: PUBLIC_ROUTES.MOBILIAR_HOME_PAGE_FR.routingPath,
        loadChildren: () => import('./pages/public/home-page/home-page.routes'),
        data: {
          [HomePageTypeKey]: HomePageTypes.Mobiliar,
        },
      },
      {
        path: PUBLIC_ROUTES.MOBILIAR_HOME_PAGE_IT.routingPath,
        loadChildren: () => import('./pages/public/home-page/home-page.routes'),
        data: {
          [HomePageTypeKey]: HomePageTypes.Mobiliar,
        },
      },
      {
        path: PUBLIC_ROUTES.SEARCH.routingPath,
        loadChildren: () => import('./pages/public/search/platform-search.module').then(m => m.PlatformSearchModule),
      },
      {
        path: PUBLIC_ROUTES.CATEGORY_LANDING_PAGE_ROOT.routingPath,
        loadChildren: () =>
          import('./pages/public/category-landing-page/category-landing-page.module').then(
            m => m.CategoryLandingPageModule,
          ),
      },
      {
        path: PUBLIC_ROUTES.OFFERING_DETAIL_PAGE_ROOT.routingPath,
        loadChildren: () =>
          import('./pages/public/offering-detail-page/offering-detail-page.module').then(
            m => m.OfferingDetailPageModule,
          ),
      },
      {
        pathMatch: 'full',
        path: PUBLIC_ROUTES.PRO_DIRECTORY_PAGE.routingPath,
        loadChildren: () =>
          import('./pages/public/pro-directory-page/pro-directory-page.module').then(m => m.ProDirectoryPageModule),
      },
      {
        path: PUBLIC_ROUTES.OFFERING_PAGE_ROOT.routingPath,
        loadChildren: () => import('./pages/public/offering-page/offering-page.module').then(m => m.OfferingPageModule),
      },
      {
        path: PUBLIC_ROUTES.CRAFTSMEN_REGION_PAGE_ROOT.routingPath,
        loadChildren: () =>
          import('./pages/public/craftsmen-region-directory/craftsmen-region-directory.module').then(
            m => m.CraftsmenRegionDirectoryModule,
          ),
      },
      {
        path: PUBLIC_ROUTES.EMERGENCY_LANDING_PAGE_ROOT.routingPath,
        loadChildren: () =>
          import('./pages/public/emergency-landing-page/emergency-landing-page.module').then(
            m => m.EmergencyLandingPageModule,
          ),
      },
      {
        path: PUBLIC_ROUTES.ARTICLE_ROOT.routingPath,
        loadChildren: () => import('./pages/public/article/article.module').then(m => m.ArticleModule),
      },
      {
        path: PUBLIC_ROUTES.INDUSTRY_OVERVIEW_PAGE_ROOT.routingPath,
        loadChildren: () =>
          import('./pages/public/industry-overview-page/industry-overview-page.module').then(
            m => m.IndustryOverviewPageModule,
          ),
      },
      {
        path: PUBLIC_ROUTES.SERVICE_OVERVIEW_PAGE_ROOT.routingPath,
        loadChildren: () =>
          import('./pages/public/service-overview-page/service-overview-page.module').then(
            m => m.ServiceOverviewPageModule,
          ),
      },
      {
        path: PUBLIC_ROUTES.ABOUT_US.routingPath,
        loadChildren: () => import('./pages/public/about-us-page/about-us.module').then(m => m.AboutUsModule),
      },
      {
        path: PUBLIC_ROUTES.MEDIA.routingPath,
        loadChildren: () => import('./pages/public/media-page/media-page.module').then(m => m.MediaPageModule),
      },
      {
        path: PUBLIC_ROUTES.IMPRESSUM.routingPath,
        loadChildren: () =>
          import('./pages/public/impressum-page/impressum-page.module').then(m => m.ImpressumPageModule),
      },
      {
        path: PUBLIC_ROUTES.SIGN_IN.routingPath,
        loadChildren: () => import('./pages/public/auth/sign-in/sign-in.module').then(m => m.SignInModule),
      },
      {
        path: PUBLIC_ROUTES.LEGAL.routingPath,
        loadChildren: () => import('./pages/public/legal/legal.module').then(m => m.LegalModule),
      },
      {
        path: PUBLIC_ROUTES.CALCULATOR_PAGE.routingPath,
        loadChildren: () => import('./pages/public/calculators/calculators.module').then(m => m.CalculatorsModule),
      },
      {
        path: PUBLIC_ROUTES.PRO_REGISTER.routingPath,
        loadChildren: () => import('./pages/public/pro-register/pro-register.module').then(m => m.ProRegisterModule),
      },
      {
        path: PUBLIC_ROUTES.PRO_PROFILE_ROOT.routingPath,
        loadChildren: () =>
          import('./pages/public/public-pro-profile-page/public-pro-profile-page.module').then(
            m => m.PublicProProfilePageModule,
          ),
      },
      {
        path: PUBLIC_ROUTES.JOB_REQUEST.routingPath,
        loadChildren: () => import('./pages/public/job-request-page/job-request.module').then(m => m.JobRequestModule),
        data: {
          [MinimalistFooterRouteParam]: true,
        },
      },
      {
        path: PUBLIC_ROUTES.AGENCY_PUBLIC_SPACE.routingPath,
        children: [
          // job request funnel under the agency space
          {
            path: PUBLIC_ROUTES.JOB_REQUEST.routingPath,
            loadChildren: () =>
              import('./pages/public/job-request-page/job-request.module').then(m => m.JobRequestModule),
            data: {
              [MinimalistFooterRouteParam]: true,
            },
          },
        ],
      },
      {
        path: PUBLIC_ROUTES.ERNEUREBARHEIZEN.routingPath,
        loadChildren: () =>
          import('./pages/public/erneuerbarheizen/erneuerbarheizen.module').then(m => m.ErneuerbarheizenModule),
      },
      {
        path: 'client', // hardcoded to allow tree shaking routes
        loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule),
        data: {
          [MinimalistFooterRouteParam]: true,
        },
      },
      {
        path: 'pro', // hardcoded to allow tree shaking routes
        loadChildren: () => import('./pages/pro/pro.module').then(m => m.ProModule),
        data: {
          [MinimalistFooterRouteParam]: true,
          [HideOfferingSearchInNavbarRouteParam]: true,
        },
      },
      {
        path: 'agent', // hardcoded to allow tree shaking routes
        loadChildren: () => import('./pages/agent/agent.module').then(m => m.AgentModule),
        data: {
          [MinimalistFooterRouteParam]: true,
          [HideOfferingSearchInNavbarRouteParam]: true,
        },
      },
      {
        path: 'admin', // hardcoded to allow tree shaking routes
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
        data: {
          [HideOfferingSearchInNavbarRouteParam]: true,
        },
      },
      {
        path: 'user', // hardcoded to allow tree shaking routes
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
        data: {
          [MinimalistFooterRouteParam]: true,
        },
      },
      {
        path: PUBLIC_ROUTES.ERROR.routingPath,
        loadChildren: () => import('./pages/public/error/error.module').then(m => m.ErrorModule),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/public/error/error.module').then(m => m.ErrorModule),
  },
]
