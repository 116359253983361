<ng-container *transloco="let t">
  @if (
    {
      colour: colour$ | async,
    };
    as ctx
  ) {
    <div
      class="py-2xs"
      [ngClass]="{
        'additional-banner__white': ctx.colour === 'white',
        'additional-banner__president': ctx.colour === 'transparent',
      }"
    >
      <div class="flex justify-between px-md py-2xs sm:px-xl">
        <div class="flex items-center gap-xs">
          <span class="text-sm font-medium">{{ t('navbar.aCompanyOf') }}</span>
          <a [routerLink]="mobiliarLink" bgoOpenNewTab>
            <bgo-mobiliar-logo size="2xs" [negative]="ctx.colour === 'transparent'" hasLink="false"></bgo-mobiliar-logo>
            <fa-icon class="ml-xs" size="xs" [icon]="externalLinkIcon"></fa-icon>
          </a>
        </div>
        <div class="flex items-center gap-lg">
          <a class="!hidden sm:!flex" cft-nav-quicklink [isSmall]="true" [routerLink]="aboutUsLink">
            <fa-icon size="xs" [icon]="aboutUsIcon"></fa-icon>{{ t('navbar.aboutUs') }}
          </a>
          <span class="!hidden sm:!flex">|</span>

          <bgo-language-picker-dropdown></bgo-language-picker-dropdown>
        </div>
      </div>
    </div>
  }
</ng-container>
