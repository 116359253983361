<div class="flex flex-col rounded-2" [ngClass]="ngClass">
  <div class="flex items-center px-sm py-xs">
    <fa-icon [icon]="icon" class="mr-sm text-[1.25rem] md:mr-md" *ngIf="icon"></fa-icon>
    <div class="mr-sm flex flex-col text-sm md:mr-md md:text-base">
      @if (title) {
        <span class="font-semibold" [innerHTML]="title"></span>
      }
      <span [innerHTML]="message"></span>
    </div>
    @if (hasCloseButton) {
      <button
        type="button"
        class="w-[1lh] shrink-0 grow rounded-2 bg-[#FFFFFF33] text-[1.25rem] leading-[2rem] hover:bg-[#FFFFFF55]"
        (click)="closed.next(true)"
      >
        <fa-icon [icon]="closeIcon"></fa-icon>
      </button>
    }
  </div>
  <!-- accent -->
  <div
    class="h-[5px] overflow-hidden rounded-b-2"
    aria-hidden="true"
    [class.bg-[#00000033]]="!isAccentColorLight"
    [class.bg-[#FFFFFF33]]="isAccentColorLight"
  >
    @if (progress && progress > -1) {
      <div
        class="h-full rounded-b-2 rounded-tr-2"
        [style.width.%]="progress"
        [class.bg-[#00000066]]="!isAccentColorLight"
        [class.bg-[#FFFFFF66]]="isAccentColorLight"
      ></div>
    }
  </div>
</div>
