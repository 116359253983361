import {DEFAULT_CURRENCY_CODE, LOCALE_ID, Provider} from '@angular/core'
import {provideTransloco, TranslocoService} from '@ngneat/transloco'
import {Language} from './language'
import {LanguageService} from './language.service'
import {environment} from '../../../environments/environment'
import {TranslocoHttpLoader} from './transloco-http-loader.service'
import {CFT_I18N_TRANSLATION_PROVIDER} from '../../craft/i18n/i18n.types'

export const provideLocaleId: () => Provider[] = () => [
  {
    provide: LOCALE_ID,
    useFactory: (translate: TranslocoService) => {
      const lang = translate.getActiveLang()
      switch (lang) {
        // use CH locale as it has major differences with German or French
        case Language.GERMAN:
        case Language.ENGLISH:
          return 'de-CH'
        case Language.FRENCH:
          return 'fr-CH'
        case Language.ITALIAN:
          return 'it-CH'
        default:
          return lang
      }
    },
    deps: [TranslocoService],
  },
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: 'CHF',
  },
]

export const provideTranslations = () => [
  provideTransloco({
    loader: TranslocoHttpLoader,
    config: {
      availableLangs: LanguageService.allSupportedLanguages(),
      defaultLang: Language.GERMAN,
      fallbackLang: Language.GERMAN,
      prodMode: environment.i18n.prodMode,
      reRenderOnLangChange: false,
    },
  }),
  {
    provide: CFT_I18N_TRANSLATION_PROVIDER,
    useFactory: (transloco: TranslocoService) => transloco,
    deps: [TranslocoService],
  },
]
