import {Component, OnDestroy, ViewContainerRef} from '@angular/core'
import {LayoutService} from './layout.service'
import {PageMetadataService} from '../../seo/page-metadata.service'
import {NavbarService} from '../navbar/navbar.service'
import {Observable, Subject} from 'rxjs'
import {AnnouncementBanner} from '../../content/types/announcement-banner.types'
import {AsyncPipe, NgClass} from '@angular/common'
import {FooterComponent} from '../footer/footer.component'
import {StructuredDataModule} from '../../seo/structured-data/structured-data.module'
import {NavbarModule} from '../navbar/navbar.module'
import {BroadcastActivateEventsModule} from '../../../shared/directives/router-outlet/broadcast-activate-events.module'
import {AnnouncementBannerComponent} from '../../../shared/components/announcement-banner/announcement-banner.component'
import {RouterModule} from '@angular/router'
import {NavbarSideMenuComponent} from '../navbar/navbar-right/navbar-side-menu/navbar-side-menu.component'
import {PageLoadProgressService} from './page-load-progress/page-load-progress.service'

@Component({
  selector: 'bgo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  standalone: true,
  imports: [
    AnnouncementBannerComponent,
    FooterComponent,
    StructuredDataModule,
    NavbarModule,
    BroadcastActivateEventsModule,
    NgClass,
    AsyncPipe,
    RouterModule,
    NavbarSideMenuComponent,
  ],
})
export class LayoutComponent implements OnDestroy {
  readonly structuredData$ = this.pageMetadataService.structuredData$
  readonly layout$ = this.layoutService.layout$
  readonly scrolled$ = this.navbarService.navbarState$
  readonly announcementBanner$: Observable<AnnouncementBanner | undefined>
  private readonly unsubscribe$ = new Subject<void>()

  constructor(
    private readonly layoutService: LayoutService,
    private readonly pageMetadataService: PageMetadataService,
    private readonly navbarService: NavbarService,
    private readonly pageLoadProgressService: PageLoadProgressService,
    viewContainerRef: ViewContainerRef,
  ) {
    this.announcementBanner$ = this.layoutService.announcementBanner$
    this.pageLoadProgressService.setupOnRouteChange(viewContainerRef)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
