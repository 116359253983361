import {inject, makeStateKey, PLATFORM_ID, TransferState} from '@angular/core'
import {InMemoryCache} from '@apollo/client/core'
import {isPlatformBrowser} from '@angular/common'
import datoGeneratedIntrospection from '../../../__generated__/datocms.possible-types'
import {provideApollo} from 'apollo-angular'
import {pageArgPagination} from './utilities/pagination'
import {GraphqlLinkFactory} from './graphql-link.factory'

/* eslint-disable @typescript-eslint/no-explicit-any */
const APOLLO_STATE_KEY = makeStateKey<any>('bgo.apollo.state')

export const provideGraphql = () =>
  provideApollo(() => {
    const factory = inject(GraphqlLinkFactory)
    const transferState = inject(TransferState)
    const platformId = inject(PLATFORM_ID)
    const isBrowser = isPlatformBrowser(platformId)

    const cache = new InMemoryCache({
      // Resolve case with empty fields where fragments with union types are used.
      // https://the-guild.dev/graphql/apollo-angular/docs/data/fragments#auto-generating-possibletypes
      possibleTypes: {...datoGeneratedIntrospection.possibleTypes},
      // type policies defined to handle pagination
      // https://www.apollographql.com/docs/react/pagination/core-api
      typePolicies: {
        Query: {
          fields: {
            searchArticles: pageArgPagination(['searchCriteria']),
          },
        },
      },
    })

    if (isBrowser) {
      const state = transferState.get<any>(APOLLO_STATE_KEY, null)
      cache.restore(state)
    } else {
      transferState.onSerialize(APOLLO_STATE_KEY, () => {
        return cache.extract()
      })
    }

    return {
      cache,
      link: factory.build(),
      ...(isBrowser
        ? {
            // queries with `forceFetch` enabled will be delayed
            ssrForceFetchDelay: 200,
          }
        : {
            // avoid to run twice queries with `forceFetch` enabled
            ssrMode: true,
          }),
    }
  })
