import {EnvironmentProviders} from '@angular/core'
import {provideToastr} from 'ngx-toastr'
import {ToastComponent} from './toast.component'

export const provideToast: () => EnvironmentProviders = () =>
  provideToastr({
    preventDuplicates: true,
    resetTimeoutOnDuplicate: true,
    // clear toastClass .ngx-toastr messes stuff up
    toastClass: '',
    toastComponent: ToastComponent,
    closeButton: true,
    easeTime: 150,
    timeOut: 5000,
    extendedTimeOut: 2000,
    progressBar: true,
    positionClass: 'toast-top-center',
  })
