import {ConfigService} from './app/core/config/config.service'
import {APP_CONFIG_TOKEN} from '../server/config'
import {mergeApplicationConfig} from '@angular/core'
import {bootstrapApplication} from '@angular/platform-browser'
import {AppComponent} from './app/app.component'
import {appConfig} from './app/app.config'

// first thing to do is load the config, as the app cannot live properly without it
// APP_INITIALIZER is not an option as it's not respected with lazy loaded modules
// https://stackoverflow.com/questions/49783765/angular-does-app-initializer-work-inside-of-lazy-loaded-modules
const loadConfig = ConfigService.loadConfig()

document.addEventListener('DOMContentLoaded', () => {
  window['bgo-ready'] = loadConfig.then(config =>
    bootstrapApplication(
      AppComponent,
      mergeApplicationConfig({providers: [{provide: APP_CONFIG_TOKEN, useValue: config}]}, appConfig),
    ).catch(err => console.error(err)),
  )
})
