import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core'
import {interval, mergeMap, Subject, takeWhile} from 'rxjs'
import {startWith} from 'rxjs/operators'
import {AsyncPipe} from '@angular/common'

@Component({
  template: `
    <div #containerElement class="fixed left-0 right-0 top-0 z-50 h-[2px]" [class.hidden]="!(showProgress$ | async)">
      <div #progressElement class="l-0 h-full bg-vermillon-500"></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class PageLoadProgressComponent {
  @ViewChild('containerElement') containerElement!: ElementRef<HTMLDivElement>
  @ViewChild('progressElement') progressElement!: ElementRef<HTMLDivElement>
  showProgress$ = new Subject<boolean>()
  private calculateProgressSpeed = PageLoadProgressComponent.progressSpeed

  constructor(private readonly cdr: ChangeDetectorRef) {}

  start() {
    this.reset()
    this.showProgress$.next(true)
    this.cdr.detectChanges()
    let width = 0
    const maxWidth = this.containerElement.nativeElement.offsetWidth

    interval(1)
      .pipe(
        mergeMap(() => this.showProgress$.pipe(startWith(true))),
        takeWhile(value => !!value),
      )
      .subscribe(() => {
        width += this.calculateProgressSpeed(width, maxWidth)
        if (this.progressElement?.nativeElement) {
          this.progressElement.nativeElement.style.width = `${width}px`
        }
        if (width >= maxWidth) {
          this.reset()
        }
      })
  }

  complete() {
    //increase progress speed to quickly complete
    this.calculateProgressSpeed = () => this.containerElement.nativeElement.offsetWidth / 100
  }

  reset() {
    this.showProgress$.next(false)
    if (this.progressElement?.nativeElement) {
      this.progressElement.nativeElement.style.width = `0`
    }
    this.calculateProgressSpeed = PageLoadProgressComponent.progressSpeed
  }

  private static progressSpeed(width: number, maxWidth: number): number {
    //nice little formula to gradually slow down progress for all device sizes
    return (1 - width / maxWidth) * 4 - width / maxWidth
  }
}
